@font-face {
  font-family: "Riona Sans W04 Black";
  src: url("https://db.onlinewebfonts.com/t/c87cf23d055242a982a9d67b083c606a.eot");
  src: url("https://db.onlinewebfonts.com/t/c87cf23d055242a982a9d67b083c606a.eot?#iefix")format("embedded-opentype"),
  url("https://db.onlinewebfonts.com/t/c87cf23d055242a982a9d67b083c606a.woff2")format("woff2"),
  url("https://db.onlinewebfonts.com/t/c87cf23d055242a982a9d67b083c606a.woff")format("woff"),
  url("https://db.onlinewebfonts.com/t/c87cf23d055242a982a9d67b083c606a.ttf")format("truetype"),
  url("https://db.onlinewebfonts.com/t/c87cf23d055242a982a9d67b083c606a.svg#Riona Sans W04 Black")format("svg");
}

@import url('https://fonts.googleapis.com/css2?family=Andika:ital,wght@0,400;0,700;1,400;1,700&family=Reddit+Mono:wght@200..900&display=swap');

/* @import url('https://fonts.cdnfonts.com/css/avenir'); */

@font-face {
  font-family: "Riona Sans W01 Light";
  src: url("https://db.onlinewebfonts.com/t/0e0b5d3e47fca436be4a9094c2129283.eot");
  src: url("https://db.onlinewebfonts.com/t/0e0b5d3e47fca436be4a9094c2129283.eot?#iefix")format("embedded-opentype"),
  url("https://db.onlinewebfonts.com/t/0e0b5d3e47fca436be4a9094c2129283.woff2")format("woff2"),
  url("https://db.onlinewebfonts.com/t/0e0b5d3e47fca436be4a9094c2129283.woff")format("woff"),
  url("https://db.onlinewebfonts.com/t/0e0b5d3e47fca436be4a9094c2129283.ttf")format("truetype"),
  url("https://db.onlinewebfonts.com/t/0e0b5d3e47fca436be4a9094c2129283.svg#Riona Sans W01 Light")format("svg");
}

@font-face {
  font-family: "Riona Sans W01 Bold";
  src: url("https://db.onlinewebfonts.com/t/72c37e84edf1d13a13a5d774056621b5.eot");
  src: url("https://db.onlinewebfonts.com/t/72c37e84edf1d13a13a5d774056621b5.eot?#iefix")format("embedded-opentype"),
  url("https://db.onlinewebfonts.com/t/72c37e84edf1d13a13a5d774056621b5.woff2")format("woff2"),
  url("https://db.onlinewebfonts.com/t/72c37e84edf1d13a13a5d774056621b5.woff")format("woff"),
  url("https://db.onlinewebfonts.com/t/72c37e84edf1d13a13a5d774056621b5.ttf")format("truetype"),
  url("https://db.onlinewebfonts.com/t/72c37e84edf1d13a13a5d774056621b5.svg#Riona Sans W01 Bold")format("svg");
}

@font-face {
  font-family: "Riona Sans W04 Black";
  src: url("https://db.onlinewebfonts.com/t/c87cf23d055242a982a9d67b083c606a.eot");
  src: url("https://db.onlinewebfonts.com/t/c87cf23d055242a982a9d67b083c606a.eot?#iefix")format("embedded-opentype"),
  url("https://db.onlinewebfonts.com/t/c87cf23d055242a982a9d67b083c606a.woff2")format("woff2"),
  url("https://db.onlinewebfonts.com/t/c87cf23d055242a982a9d67b083c606a.woff")format("woff"),
  url("https://db.onlinewebfonts.com/t/c87cf23d055242a982a9d67b083c606a.ttf")format("truetype"),
  url("https://db.onlinewebfonts.com/t/c87cf23d055242a982a9d67b083c606a.svg#Riona Sans W04 Black")format("svg");
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');


body {
  margin: 0;
  /* font-family: "Riona Sans W04 Black !important"; */
}

.customOutlinedInput input::placeholder {
  color: red; /* Change this to your desired placeholder color */
}


code {
  /* font-family: "Riona Sans W04 Black !important"; */
}
