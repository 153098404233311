/* @import url('https://fonts.googleapis.com/css2?family=Reddit+Mono:wght@200..900&display=swap');

@font-face {
  font-family: "Riona Sans W04 Black";
  src: url("https://db.onlinewebfonts.com/t/c87cf23d055242a982a9d67b083c606a.eot");
  src: url("https://db.onlinewebfonts.com/t/c87cf23d055242a982a9d67b083c606a.eot?#iefix")format("embedded-opentype"),
  url("https://db.onlinewebfonts.com/t/c87cf23d055242a982a9d67b083c606a.woff2")format("woff2"),
  url("https://db.onlinewebfonts.com/t/c87cf23d055242a982a9d67b083c606a.woff")format("woff"),
  url("https://db.onlinewebfonts.com/t/c87cf23d055242a982a9d67b083c606a.ttf")format("truetype"),
  url("https://db.onlinewebfonts.com/t/c87cf23d055242a982a9d67b083c606a.svg#Riona Sans W04 Black")format("svg");
} */

body, html {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* This prevents horizontal scrolling */
  /* font-family: "Riona Sans W04 Black"; */
}

.App {
  text-align: center;
  color: black;
  /* font-family: "Riona Sans W04 Black"; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.css-il6xok-MuiInputBase-root-MuiOutlinedInput-root .MuiInputBase-input{
  padding-left: 10px;
}

.css-9z5tot-MuiInputBase-root-MuiOutlinedInput-root .MuiInputBase-input{
  padding-left: 10px;
}

.css-ittuaa-MuiInputAdornment-root{
  margin-right: -20px;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
